.CryptoTable {
    width: 100%;
    min-width: 960px;
    height: calc(100% - 135px);
    position: absolute;
    top: 120px;
    font-family: sans-serif;
    line-height: 1.423156456;
}

.CryptoMobileTable {
    width: 100%;
    height: calc(100% - 161px);
    position: absolute;
    top: 146px;
    font-family: sans-serif;
    line-height: 1.423156456;
}

.CellWithLink {
    color: black;
}

.Sticky-React-Table--Row-Cell {
    text-align: center;
    display: flex;
    justify-content: center;
}

.Sticky-React-Table--Header-Cell {
    text-align: center;
    display: flex;
    justify-content: center;
}

.Sticky-React-Table--Row {
    align-self: flex-start !important;
}

.HeaderRow {
    border-top: 1px #ddd solid;
    align-self: flex-start !important;
}

.SortingColumnCell {
    font-weight: bold;
}

.PositiveChangeCell {
    color: green;
}

.NegativeChangeCell {
    color: red;
}

.HeaderCellLink {
    display: inline;
    color: blue;
}
