.DisclaimerBox {
    min-width: 960px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: smaller;
    border-top: 1px #ddd solid;
    background-color: #f7f7f7;
}

.DisclaimerMobileBox {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: smaller;
    border-top: 1px #ddd solid;
    background-color: #f7f7f7;
}
