.FilteringComponent {
    display: flex;
    flex-direction: column;
}

.FilterMainTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.AllFiltersBox {
    display: flex;
    flex-direction: column;
}

.FiltersRowContainer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.FilterBox {
    display: flex;
    flex: 1;
}

.FilterName {
    margin: 0 10px;
}

.FilterSlider {
    margin-top: -8px;
    width: 50%;
}

.FilterBoxMobile {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.FilterNameMobile {
    margin: 0 6px;
}

.FilterSliderMobile {
    margin-top: -12px;
    margin-right: 30px;
    width: 50%;
}

.BottomMobileContainer {
    align-items: center;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}

.FiltersCheckboxMobile {
    margin: 5px 1px;
}

.BottomRowContainer {
    align-items: center;
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
}

.FilterButtonContainer {
    align-self: center;
    width: 40%;
    margin-top: 20px;
}

.FilterButtonMobileContainer {
    align-self: center;
    width: 70%;
    margin: 5px 1px 20px 1px;
}

.ant-collapse-header {
    font-weight: bold;
}

.TagsDropdownComponent {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 1px;
}

.TagsDropdownTitle {
    text-align: right;
    display: flex;
    margin: 0 10px;
}

.TagsDropdown {
    display: flex;
}

.ShowingCryptosText {
    font-weight: normal;
}
