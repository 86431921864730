.LogoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.LogoImage {
    height: 30px;
    padding: 0 8px 0 8px;
}

.LogoTitle {
    font-family: 'Exo 2', sans-serif;
    font-size: 25px;
}

.LogoContainerMobile {
    display: flex;
    justify-content: center;
    align-items: center;
}

.LogoTitleRowMobile {
    margin-right: 9px;
    font-family: 'Exo 2', sans-serif;
    font-size: 9px;
    font-weight: 900;
    line-height: 10px;
}