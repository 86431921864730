.OrderingComponent {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.OrderMainTitle {
    text-align: right;
    display: flex;
    margin: 0 10px;
    font-weight: bold;
}

.OrderDropdowns {
    display: flex;
}
