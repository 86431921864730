.DonateBox {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.QRCode {
    margin-left: 35px;
}

.ETHLink {
    margin-left: 5px;
}