.NeedDevButtonContainer {
    margin-top: 1px;
}

.TopMsg {
    text-align: center;
    margin-bottom: 20px;
}

.FooterButton {
    float: right;
    margin: 0 0 0 8px;
}