body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.CryptoTable {
    width: 100%;
    min-width: 960px;
    height: calc(100% - 135px);
    position: absolute;
    top: 120px;
    font-family: sans-serif;
    line-height: 1.423156456;
}

.CryptoMobileTable {
    width: 100%;
    height: calc(100% - 161px);
    position: absolute;
    top: 146px;
    font-family: sans-serif;
    line-height: 1.423156456;
}

.CellWithLink {
    color: black;
}

.Sticky-React-Table--Row-Cell {
    text-align: center;
    display: flex;
    justify-content: center;
}

.Sticky-React-Table--Header-Cell {
    text-align: center;
    display: flex;
    justify-content: center;
}

.Sticky-React-Table--Row {
    align-self: flex-start !important;
}

.HeaderRow {
    border-top: 1px #ddd solid;
    align-self: flex-start !important;
}

.SortingColumnCell {
    font-weight: bold;
}

.PositiveChangeCell {
    color: green;
}

.NegativeChangeCell {
    color: red;
}

.HeaderCellLink {
    display: inline;
    color: blue;
}

.OrderingComponent {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.OrderMainTitle {
    text-align: right;
    display: flex;
    margin: 0 10px;
    font-weight: bold;
}

.OrderDropdowns {
    display: flex;
}

.FilteringComponent {
    display: flex;
    flex-direction: column;
}

.FilterMainTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.AllFiltersBox {
    display: flex;
    flex-direction: column;
}

.FiltersRowContainer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.FilterBox {
    display: flex;
    flex: 1 1;
}

.FilterName {
    margin: 0 10px;
}

.FilterSlider {
    margin-top: -8px;
    width: 50%;
}

.FilterBoxMobile {
    display: flex;
    flex: 1 1;
    justify-content: flex-end;
}

.FilterNameMobile {
    margin: 0 6px;
}

.FilterSliderMobile {
    margin-top: -12px;
    margin-right: 30px;
    width: 50%;
}

.BottomMobileContainer {
    align-items: center;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}

.FiltersCheckboxMobile {
    margin: 5px 1px;
}

.BottomRowContainer {
    align-items: center;
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
}

.FilterButtonContainer {
    align-self: center;
    width: 40%;
    margin-top: 20px;
}

.FilterButtonMobileContainer {
    align-self: center;
    width: 70%;
    margin: 5px 1px 20px 1px;
}

.ant-collapse-header {
    font-weight: bold;
}

.TagsDropdownComponent {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 1px;
}

.TagsDropdownTitle {
    text-align: right;
    display: flex;
    margin: 0 10px;
}

.TagsDropdown {
    display: flex;
}

.ShowingCryptosText {
    font-weight: normal;
}

.LogoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.LogoImage {
    height: 30px;
    padding: 0 8px 0 8px;
}

.LogoTitle {
    font-family: 'Exo 2', sans-serif;
    font-size: 25px;
}

.LogoContainerMobile {
    display: flex;
    justify-content: center;
    align-items: center;
}

.LogoTitleRowMobile {
    margin-right: 9px;
    font-family: 'Exo 2', sans-serif;
    font-size: 9px;
    font-weight: 900;
    line-height: 10px;
}
.SpinnerBox {
    width: 100%;
    height: calc(100% - 175px);
    position: absolute;
    top: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.SpinnerImage {
    opacity: 0.5;
    height: 150px;
}
.DonateBox {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.QRCode {
    margin-left: 35px;
}

.ETHLink {
    margin-left: 5px;
}
.DisclaimerBox {
    min-width: 960px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: smaller;
    border-top: 1px #ddd solid;
    background-color: #f7f7f7;
}

.DisclaimerMobileBox {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: smaller;
    border-top: 1px #ddd solid;
    background-color: #f7f7f7;
}

.FeedbackButtonContainer {
    margin-top: 1px;
}

.ShareButtonsRow {
    padding-top: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.SaveCurrentViewButtonBox {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SaveCurrentViewButtonBoxMobile {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
}

.FullLinkAboveText {
    display: flex;
    justify-content: center;
    font-weight: bold;
}

.FullLink {
    margin: 5px 5px 15px 5px;
    display: flex;
    justify-content: center;
    word-break: break-all;
}

.ClipboardCopiedMsg {
    color: green;
    display: flex;
    justify-content: center;
}

.ShareOrText {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: larger;
}

.BookmarkComponentWrapper {
    margin: 10px 10px 1px 10px;
    display: flex;
    justify-content: center;
}

.BookmarkComponentClass {

}

.NeedDevButtonContainer {
    margin-top: 1px;
}

.TopMsg {
    text-align: center;
    margin-bottom: 20px;
}

.FooterButton {
    float: right;
    margin: 0 0 0 8px;
}
.NoDataFoundBox {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.NoDataFoundTextLine1 {
    font-weight: bold;
    font-size: larger;
    z-index: 99;
}

.NoDataFoundTextLine2 {
    font-weight: bold;
    font-size: larger;
    z-index: 99;
}

.MainView {
    display: flex;
    flex-direction: column;
}

.TopContainer {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.TopMobileContainer {
    height: 70px;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
}

.DonateFeedbackRow {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.ShareMobileRow {
    position: absolute;
    width: 100%;
    top: 118px;
    display: flex;
    align-items: center;
    justify-content: center;
}
