.SaveCurrentViewButtonBox {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SaveCurrentViewButtonBoxMobile {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
}

.FullLinkAboveText {
    display: flex;
    justify-content: center;
    font-weight: bold;
}

.FullLink {
    margin: 5px 5px 15px 5px;
    display: flex;
    justify-content: center;
    word-break: break-all;
}

.ClipboardCopiedMsg {
    color: green;
    display: flex;
    justify-content: center;
}

.ShareOrText {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: larger;
}

.BookmarkComponentWrapper {
    margin: 10px 10px 1px 10px;
    display: flex;
    justify-content: center;
}

.BookmarkComponentClass {

}
