.NoDataFoundBox {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.NoDataFoundTextLine1 {
    font-weight: bold;
    font-size: larger;
    z-index: 99;
}

.NoDataFoundTextLine2 {
    font-weight: bold;
    font-size: larger;
    z-index: 99;
}
