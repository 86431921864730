.SpinnerBox {
    width: 100%;
    height: calc(100% - 175px);
    position: absolute;
    top: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.SpinnerImage {
    opacity: 0.5;
    height: 150px;
}