.MainView {
    display: flex;
    flex-direction: column;
}

.TopContainer {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.TopMobileContainer {
    height: 70px;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
}

.DonateFeedbackRow {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.ShareMobileRow {
    position: absolute;
    width: 100%;
    top: 118px;
    display: flex;
    align-items: center;
    justify-content: center;
}